<template>
    <div>
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <!--
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                            <div>
                                <i class="icon-phone2 mr-1 align-middle"></i>
                                <span class="align-middle">{{rowReg.bu_phone||"-"}}</span>
                            </div>
                        </div>
                        -->
                        <div class="d-flex flex-column">
                            <a href="javascript:;" @click="toDetailKajianPerawatUGD()" data-toggle="modal" data-target="#modalKajianAwalPerawat" class="btn btn-outline-light">Lihat Kajian Perawat</a>
                            <a href="javascript:;" v-if="row.have_rajal" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1" @click="doOpenRiwayatRMV2(row.kajianRajal)">Lihat Riwayat Rekam Medis Rajal</a>
                            <a href="javascript:;" @click="doOpenRiwayatRMUGD(row)" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis UGD</a>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>

        <div class="content overflow-hidden mt-2">
            <div class="alert alert-info">Verifikasi pilihan ICD10 dan ICD9 CM pada bagian <strong>Asesmen</strong></div>
            
            <validation-observer ref="VFormSubmit">
            <b-form @submit.prevent="doSubmit">

            <div class="accordion" id="medRecAccordion">
            <div class="card">
                <div class="card-header bg-info c-pointer" @click="doOpenCollapse('col1')">
                <h5 class="card-title font-weight-semibold">Subjective</h5>
                </div>
                <div>
                <div class="card-body" v-show="collapseOpen.col1">
                    <div class="table-responsive">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Keluhan Utama</h4>
                                <p>{{row.ausd_keluhan||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Riwayat Penyakit</h4>
                                <p>{{row.ausd_riwayat||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Catatan Dokter</h4>
                                <p>{{row.ausd_catatan_dokter||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="form-row mt-2">
                        <div class="col-md-6">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Alergi</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="form-row" v-if="row.ausd_has_obat_rutin == 'Y'">
                                    <div class="col-md-12">
                                        <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr v-for="(v,k) in (row.ausd_alergi||[])" :key="k+'ob'">
                                                    <td>
                                                        <div class="result_tab">
                                                        {{v.jenis||"-"}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="result_tab">
                                                        {{v.name||"-"}}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="form-row" v-else>
                                        <div class="col-md-12">
                                            <span>Tidak Ada Obat Rutin</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Obat Rutin</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="form-row" v-if="row.ausd_has_obat_rutin == 'Y'">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Keterangan</h4>
                                                <p>{{row.ausd_keterangan_obat_rutin||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                                <div class="form-row" v-else>
                                    <div class="col-md-12">
                                        <span>Tidak Ada Obat Rutin</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header bg-info c-pointer" @click="doOpenCollapse('col2')">
                <h5 class="card-title font-weight-semibold">Objective</h5>
                </div>
                <div>
                <div class="card-body" v-show="collapseOpen.col2">
                    <div>
                    <div class="result_tab mb-2">
                        <h4>Keadaan Umum</h4>
                        <p>{{row.auod_keadaan_umum||"-"}}</p>
                    </div>
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                        </div>
                        <div class="card-body p-3">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Tekanan Darah</h4>
                                        <p v-if="row.auod_ttv_tekanan_darah_min || row.auod_ttv_tekanan_darah_max">{{row.auod_ttv_tekanan_darah_min}} / {{row.auod_ttv_tekanan_darah_max}} mmHG</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Nadi</h4>
                                        <p v-if="row.auod_ttv_nadi">{{row.auod_ttv_nadi||"-"}}/mnt</p>
                                        <p v-else> - </p>
                                        
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Gula Darah</h4>
                                        <p v-if="row.auod_ttv_gula_darah">{{row.auod_ttv_gula_darah||"-"}}mg/dL</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Pernafasan</h4>
                                        <p v-if="row.auod_ttv_pernafasan">{{row.auod_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>SPO2</h4>
                                        <p v-if="row.auod_ttv_spo2">{{row.auod_ttv_spo2||"-"}}%</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Suhu</h4>
                                        <p v-if="row.auod_ttv_suhu">{{row.auod_ttv_suhu||"-"}}C</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Berat Badan</h4>
                                        <p v-if="row.auod_ttv_weight">{{row.auod_ttv_weight||"-"}}kg</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Tinggi</h4>
                                        <p v-if="row.auod_ttv_height">{{row.auod_ttv_height||"-"}}Cm</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Lingkar Kepala</h4>
                                        <p v-if="row.auod_ttv_lingkar_kepala">{{row.auod_ttv_lingkar_kepala||"-"}}cm</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td v-if="row.ap_usia >= 2">
                                        <div class="result_tab">
                                        <h4>BMI</h4>
                                        <p v-if="row.auod_ttv_bmi">{{row.auod_ttv_bmi}}m2</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    <td v-if="row.ap_usia <= 15">
                                        <div class="result_tab">
                                        <h4>Luas Permukaan Tubuh Anak</h4>
                                        <p v-if="row.auod_ttv_luas_permukaan_anak">{{row.auod_ttv_luas_permukaan_anak}}kg/m2</p>
                                        <p v-else> - </p>
                                        </div>
                                    </td>
                                    <!--
                                    <td colspan="2">
                                        <div class="result_tab">
                                        <h4>Kesadaran</h4>
                                        <p>{{getConfigDynamic(masterKajianDokter.mKesadaran,row.auod_ttv_kesadaran)||"-"}}</p>
                                        </div>
                                    </td>
                                    -->
                                    <td colspan="2">
                                        <div class="result_tab">
                                        <h4>IMT</h4>
                                        <p>{{row.auod_ttv_imt||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Kesadaran GCS</h6>
                        </div>
                        <div class="card-body p-3">
                            <table class="table table-bordered">
                            <tbody>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Respons Mata</h4>
                                    <p>{{getConfigDynamic(Config.mr.ksResponseMata,row.auod_response_mata)||"-"}}<span class="font-weight-semibold text-info">({{row.auod_response_mata||0}})</span></p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Respons Verbal</h4>
                                    <p>{{getConfigDynamic(Config.mr.ksResponseVerbal,row.auod_response_verbal)||"-"}}<span class="font-weight-semibold text-info">({{row.auod_response_verbal||0}})</span></p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Respons Motorik</h4>
                                    <p>{{getConfigDynamic(Config.mr.ksResponseMotorik,row.auod_response_motorik)||"-"}}<span class="font-weight-semibold text-info">({{row.auod_response_motorik||0}})</span></p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td colspan="3">
                                    <div class="result_tab">
                                    <h4>Nilai GCS</h4>
                                    <p>{{row.auod_gcs_total||0}} - 
                                    <span v-if="row.auod_gcs_total >= 14"> Composmentis</span> 
                                    <span v-else-if="row.auod_gcs_total >= 12 && row.auod_gcs_total <= 13">Apatis</span>
                                    <span v-else-if="row.auod_gcs_total >= 10 && row.auod_gcs_total <= 11">Delirium</span>
                                    <span v-else-if="row.auod_gcs_total >= 7 && row.auod_gcs_total <= 9">Somonolen</span>
                                    <span v-else-if="row.auod_gcs_total >= 5 && row.auod_gcs_total <= 6">Sopor</span>
                                    <span v-else-if="row.auod_gcs_total >= 4 && row.auod_gcs_total <= 4">Semi-Comma</span>
                                    <span v-else>Coma</span>
                                    </p>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card">  
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
                        </div>
                        
                        <div class="card-body p-3" v-if="row.ap_usia > 6">
                            <div class="form-group">
                            <label for="">Skala Nyeri</label>
                            <div class="pain-scale no-pain" v-if="row.auod_skala_nyeri_value == 0">
                                <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                                <strong>Tidak Ada Nyeri</strong>
                            </div>
                            <div class="pain-scale mild-pain" v-if="row.auod_skala_nyeri_value >= 1 && row.auod_skala_nyeri_value <= 3">
                                <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                                <strong>Sedikit Nyeri</strong>
                            </div>
                            <div class="pain-scale moderate-pain" v-if="row.auod_skala_nyeri_value >= 4 && row.auod_skala_nyeri_value <= 5">
                                <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                                <strong>Agak Mengganggu</strong>
                            </div>
                            <div class="pain-scale severe-pain" v-if="row.auod_skala_nyeri_value >= 6 && row.auod_skala_nyeri_value <= 7">
                                <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                                <strong>Mengganggu Aktivitas</strong>
                            </div>
                            
                            <div class="pain-scale worst-pain" v-if="row.auod_skala_nyeri_value >= 8 && row.auod_skala_nyeri_value <= 9">
                                <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                                <strong>Sangat Mengganggu</strong>
                            </div>
                            <div class="pain-scale worst-pain" v-if="row.auod_skala_nyeri_value >= 10">
                                <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                                <strong>Tak Tertahankan</strong>
                            </div>
                            </div>
                        </div>

                        <div class="card-body p-3" v-else-if="row.ap_usia <= 6 && row.ap_usia >= 1">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">FLACC Scale</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="form-group">
                                    <label for="kidPainScale">Skala Nyeri</label>
                                    <table class="table table-bordered pain-scale-table">
                                    <thead>
                                        <tr>
                                        <th>Pengkajian</th>
                                        <th>Hasil</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(v,k) in row.auod_skala_nyeri" :key="k+'akn'">
                                            <th class="table-warning text-center"><img :src="$parent.$parent.$parent.assetLocal(v.img)"
                                                style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                                                {{v.name}}</th>
                                            <td>
                                                {{getConfigDynamic(v.configVal,v.value)||"-"}}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="table-info">
                                        <td colspan="4" class="text-info-700">
                                            <span>NILAI SKALA NYERI: {{row.auod_skala_nyeri_value||"-"}}</span>
                                            <span v-if="row.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                                            <span v-else-if="row.auod_skala_nyeri_value >= 1 && row.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                                            <span v-else-if="row.auod_skala_nyeri_value >= 4 && row.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                                        </td>
                                        </tr>
                                    </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="card-body p-3" v-else>
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h6>
                            </div>
                            <div class="card-body p-3">
                            <div class="form-group">
                                <label for="kidPainScale">Skala Nyeri</label>
                                <table class="table table-bordered pain-scale-table">
                                <thead>
                                    <tr>
                                    <th>Pengkajian</th>
                                    <th>Hasil</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in row.auod_skala_nyeri_nips" :key="k+'akn'">
                                        <th class="table-warning text-center"><img :src="$parent.$parent.$parent.assetLocal(v.img)"
                                            style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                                            {{v.name}}</th>
                                        <td>
                                            {{getConfigDynamic(v.configVal,v.value)||"-"}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr class="table-info">
                                    <td colspan="4" class="text-info-700">
                                        <span>NILAI SKALA NYERI: {{row.auod_skala_nyeri_value||0}}</span>
                                        
                                        <span v-if="row.auod_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                        <span v-else-if="row.auod_skala_nyeri_value >= 3 && row.auod_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                    </td>
                                    </tr>
                                </tfoot>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header bg_head_panel" @click="keadaanUmum = !keadaanUmum">
                        <h6 class="card-title font-weight-semibold" 
                        >Keadaan Umum</h6>
                        </div>
                        <div class="card-body p-3" v-if="keadaanUmum">
                        <table class="table table-bordered">
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Kepala</h4>
                                <p v-if="row.auod_vs_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_kepala == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_kepala_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Mata</h4>
                                <p v-if="row.auod_vs_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_mata == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_mata_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Hidung</h4>
                                <p v-if="row.auod_vs_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_hidung == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_hidung_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Rambut</h4>
                                <p v-if="row.auod_vs_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_rambut == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_rambut_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Bibir</h4>
                                <p v-if="row.auod_vs_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_bibir == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_bibir_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Gigi Geligi</h4>
                                <p v-if="row.auod_vs_gigi == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_gigi == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_gigi_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Lidah</h4>
                                <p v-if="row.auod_vs_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_lidah == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_lidah_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Langit-Langit</h4>
                                <p v-if="row.auod_vs_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_langit == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_langit_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Leher</h4>
                                <p v-if="row.auod_vs_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_leher == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_leher_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Tenggorokan</h4>
                                <p v-if="row.auod_vs_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_tenggorokan == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_tenggorokan_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Tonsil</h4>
                                <p v-if="row.auod_vs_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_tonsil == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_tonsil_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Dada</h4>
                                <p v-if="row.auod_vs_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_dada == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_dada_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Payudara</h4>
                                <p v-if="row.auod_vs_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_payudara == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_payudara_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Punggung</h4>
                                <p v-if="row.auod_vs_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_punggung == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_punggung_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Perut</h4>
                                <p v-if="row.auod_vs_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_perut == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_perut_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Genital</h4>
                                <p v-if="row.auod_vs_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_genital == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_genital_text||"-"}}</p>
                                </div>
                            </td>
                            
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Anus</h4>
                                <p v-if="row.auod_vs_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_anus == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_anus_text||"-"}}</p>
                                </div>
                            </td>
                            
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Lengan Atas</h4>
                                <p v-if="row.auod_vs_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_lengan_atas == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_lengan_atas_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Lengan Bawah</h4>
                                <p v-if="row.auod_vs_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_lengan_bawah_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Jari Tangan</h4>
                                <p v-if="row.auod_vs_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_jari_tangan == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_jari_tangan_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Kuku Tangan</h4>
                                <p v-if="row.auod_vs_kuku == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_kuku == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_kuku_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Sendi Tangan</h4>
                                <p v-if="row.auod_vs_sendi_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_sendi_tangan == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_sendi_tangan_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Tungkai Atas</h4>
                                <p v-if="row.auod_vs_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_tungkai_atas_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Tungkai Bawah</h4>
                                <p v-if="row.auod_vs_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_tungkai_bawah_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Jari Kaki</h4>
                                <p v-if="row.auod_vs_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_jari_kaki == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_jari_kaki_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Kuku Kaki</h4>
                                <p v-if="row.auod_vs_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_kuku_kaki_text||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Sendi Kaki</h4>
                                <p v-if="row.auod_vs_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                <p v-else-if="row.auod_vs_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                                <p v-else>{{row.auod_vs_persendian_kaki_text||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                                <td width="33%" colspan="3">
                                    <div class="result_tab">
                                    <h4>Saraf</h4>
                                    <p v-if="row.auod_vs_saraf == 'N'">TAK (Tidak Ada Kelainan)</p>
                                    <p v-else-if="row.auod_vs_saraf == 'TP'">Tidak Diperiksa</p>
                                    <p v-else>
                                        <table class="table table-bordered">
                                            <tr>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Refleks Fisiologis</h4>
                                                    <p>{{row.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Refleks Patologis</h4>
                                                    <p>{{row.auod_vs_saraf_r_patologis||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Babinski</h4>
                                                    <p>{{row.auod_vs_saraf_babinski||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Lasegue</h4>
                                                    <p>{{row.auod_vs_saraf_lasegue||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Bregard</h4>
                                                    <p>{{row.auod_vs_saraf_bregard||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Sicard</h4>
                                                    <p>{{row.auod_vs_saraf_sicard||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Kaku Duduk</h4>
                                                    <p>{{row.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Kernig Sign</h4>
                                                    <p>{{row.auod_vs_saraf_kernig_sign||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Brudzinski 1</h4>
                                                    <p>{{row.auod_vs_saraf_brudzinski||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td width="50%">
                                                    <div class="result_tab">
                                                    <h4>Brudzinski 2</h4>
                                                    <p>{{row.auod_vs_saraf_brudzinski2||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        </div>
                    </div>

                    
                    <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Kajian Primer</h6>
                        </div>
                        <div class="card-body p-3">
                            <div class="mb-2">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th colspan="2">Airways</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kondisi</h4>
                                        <p>{{getConfigDynamic(Config.mr.bebasTidakOpt,row.auod_airways_val)||"-"}} - {{airwaysVal}}</p>
                                        
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Suara Nafas</h4>
                                        <p v-if="row.auod_airways_suara_napas != 'L'">{{row.auod_airways_suara_napas||'-'}}</p>
                                        <p v-else>{{row.auod_airways_suara_napas_text||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div class="mb-2">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th colspan="2">Breathing</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Kondisi</h4>
                                        <p>{{getConfigDynamic(Config.mr.normalTidakNormalOpt,row.auod_breathing_val)||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Pola Nafas</h4>
                                        <p>{{getConfigDynamic(Config.mr.polaNapasOpt,row.auod_breathing_pola_napas)||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Frekuensi Nafas</h4>
                                        <p v-if="row.auod_breathing_frekuensi && row.auod_breathing_label">
                                        {{row.auod_breathing_frekuensi||0}} x/mnt - {{row.auod_breathing_label}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Bunyi Nafas</h4>
                                        <p>{{row.auod_breathing_bunyi_nafas||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tanda Distress Pernafasan</h4>
                                        <p>{{row.auod_breathing_tanda_napas||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Jenis Pernafasan</h4>
                                        <p>{{row.auod_breathing_jenis_nafas||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div class="mb-2">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th colspan="2">Circulation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Kondisi</h4>
                                        <p>{{getConfigDynamic(Config.mr.normalTidakNormalOpt,row.auod_circulation_val)||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Ketidak Normalan</h4>
                                        <p>{{circulationVal||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div class="mb-2">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th colspan="2">Disability</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Kondisi</h4>
                                            <p>{{getConfigDynamic(Config.mr.normalTidakNormalOpt,row.auod_disability_val)||"-"}}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Disability</h4>
                                            <p>{{getConfigDynamic(Config.mr.disabilty,row.auod_disability_tidak_normal)||"-"}}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="table-secondary">
                                    <th colspan="2">Kekuatan Otot</th>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tangan Kanan</h4>
                                        <p>{{row.auod_disability_tangan_kanan||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tangan Kiri</h4>
                                        <p>{{row.auod_disability_tangan_kiri||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kaki Kanan</h4>
                                        <p>{{row.auod_disability_kaki_kanan||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kaki Kiri</h4>
                                        <p>{{row.auod_disability_kaki_kiri||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div>
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th colspan="2">Environment</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Adanya trauma pada daerah</h4>
                                        <p>{{row.auod_env_trauma||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Adanya jejas / luka pada daerah</h4>
                                        <p>{{row.auod_env_jelas_luka||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Ukuran luka</h4>
                                        <p>{{row.auod_env_ukuran_luka||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kedalaman luka</h4>
                                        <p>{{row.auod_env_kedalaman_luka||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Lain-lain</h4>
                                        <p>{{row.auod_env_lainnya||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                        <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
                        </div>
                        
                        <div class="card-body p-3">
                            <div class="row gx-2">
                                <div class="col-lg-4">
                                <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                                <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in row.auod_lokasi_nyeri||[]" :key="k+'nav'">
                                    <a href="javascript:;" data-toggle="tab" 
                                    :class="activeAnat == k ? 'nav-link active':'nav-link'">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>{{v.name||"(Anatomi Baru)"}}</span>
                                        <div>
                                        <i class="icon-chevron-right"></i>
                                        </div>
                                    </div>
                                    </a>
                                </li>
                                </ul>
                                </div>
                                <div class="col-lg-8">
                                <div class="tab-content">
                                    <div v-for="(v,k) in row.auod_lokasi_nyeri||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                                    <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                                        <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                                        <div class="body_wrapper">
                                            <img :src="$parent.$parent.$parent.assetLocal(v.image)" class="img-fluid w-100" alt="">
                                        </div>
                                        <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                                            :style="`top:${v1.y}%;left:${v1.x}%`">
                                            <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                            :title="v1.value"></a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header bg-info c-pointer" @click="doOpenCollapse('col3')">
                <h5 class="card-title font-weight-semibold">Assessment</h5>
                </div>
                <div>
                <div class="card-body" v-show="collapseOpen.col3">
                    <div class="alert alert-info">Verifikasi pilihan ICD-10 dan ICD-9-CM</div>
                    
                    <div class="card">
                    <div class="card-header bg_head_panel shadow-0 border">
                        <span class="card-title font-weight-semibold mb-0">Verifikasi ICD</span>
                    </div>
    
                    <div class="card-group">
                        <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Diagnosis Dokter</span>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD10Dok||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD10Dok||[])" :key="k">
                                <template v-if="v.mi10_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    title="Klik untuk menjadikan Diagnosis Primer">{{v.mi10_name}} <span
                                    class="font-weight-semibold">[{{v.mi10_code}}]</span></span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                        </div>
    
                        <div class="card-body">
                            <div class="req-list">
                            <h6 class="text-uppercase mb-0">Diagnosa Lainnya</h6>
                            <div>
                                <span class="align-middle">{{row.auod_diagnosa||"-"}}</span>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Verifikasi Diagnosis</span>
                        </div>
                        <div class="card-body">
                            <div class="form-group mb-0">
                            <div class="input-group">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                    v-model="inputICD10" @search="filterICD10($event,0)" @input="selectICD10(inputICD10)"
                                    :options="mICD10" label="text" :clearable="true" :reduce="v=>v.value">
    
                                    <template slot="selected-option">
                                    <span v-b-tooltip.hover.right :title="inputICD10Name">
                                        {{inputICD10Name||"-"}}
                                    </span>
                                    </template>
    
                                    <template slot="option" slot-scope="option">
                                    <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                    </template>
                                    <template #list-footer v-if="(mICD10||[]).length >= 1">
                                    <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                        <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                            class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{mICD10[0].currentPage}} dari
                                            {{ceilData(mICD10[0].totalRows/5)}}</small>
                                        <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                            class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                    </li>
                                    </template>
                                </v-select>
                                </div>
                            </div>
                            </div>
                        </div>
                        <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                            <thead>
                            <tr>
                                <th width="64">Primer</th>
                                <th>Item ICD-10 dipilih</th>
                                <th width="64">Aksi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                                <td>
                                <template>
                                    <div class="custom-control custom-radio custom-control-inline">
                                    <input v-model="dataICD10Primer" :value="v.auicd10r_id" type="radio"
                                        name="primaryDiagnose10" class="custom-control-input"
                                        :id="`primaryDiagnose10${k}`" /><label class="custom-control-label"
                                        :for="`primaryDiagnose10${k}`"></label></div>
    
                                    <VValidate :name="'Diagnosa Primer'" v-model="dataICD10Primer" :rules="{required:1}" />
                                </template>
                                </td>
                                <td><span>{{v.mi10_name||"-"}}<span
                                    class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                                <td>
                                <a href="javascript:;" @click="removedataICD10(v.auicd10r_id, k)"
                                    class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                    <i class="icon-trash-alt"></i>
                                </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                        </div>
                        </div>
                    </div>
    
                    <div class="card-group mt-3">
                        <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <h6 class="card-title mb-0 font-weight-semibold">Tindakan Dokter</h6>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD9Dok||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD9Dok||[])" :key="k">
                                <template v-if="v.mi9_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    title="Klik untuk menjadikan Diagnosis Primer">{{v.mi9_name}} <span
                                    class="font-weight-semibold">[{{v.mi9_code}}]</span></span>
                                
                                  <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.auicd9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                  <i class="icon-file-eye2"></i>
                                  </a>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>
    
                        <div class="card-body">
                            <div class="req-list">
                            <h6 class="text-uppercase mb-0">Tindakan Lainnya</h6>
                            <div>
                                <span class="align-middle">{{row.auod_tindakan||"-"}}</span>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <h6 class="card-title mb-0 font-weight-semibold">Verifikasi Tindakan</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mb-0">
                            <div class="input-group">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                    v-model="inputICD9" @search="filterICD9($event,0)" @input="selectICD9(inputICD9)"
                                    :options="mICD9" label="text" :clearable="true" :reduce="v=>v.value">
    
                                    <template slot="selected-option">
                                    <span v-b-tooltip.hover.right :title="inputICD9Name">
                                        {{inputICD9Name||"-"}}
                                    </span>
                                    </template>
    
                                    <template slot="option" slot-scope="option">
                                    <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                    </template>
                                    <template #list-footer v-if="(mICD9||[]).length >= 1">
                                    <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                        <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')" size="sm"
                                            variant="transparent" class="btn-icon bg-blue-300"><i
                                            class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{mICD9[0].currentPage}} dari
                                            {{ceilData(mICD9[0].totalRows/5)}}</small>
                                        <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')" size="sm"
                                            variant="transparent" class="btn-icon bg-blue-300"><i
                                            class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                    </li>
                                    </template>
                                </v-select>
                                </div>
                            </div>
                            </div>
                        </div>
                        <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                            <thead>
                            <tr>
                                <th>Item ICD-9 dipilih</th>
                                <th width="64">Aksi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                                <td><span>{{v.mi9_name||"-"}}<span
                                    class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                                <td>
                                <a href="javascript:;" @click="removedataICD9(v.auicd9r_id, k)"
                                    class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                    <i class="icon-trash-alt"></i>
                                </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>
                        </div>
                    </div>
    
                    </div>
                </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header bg-info c-pointer" @click="doOpenCollapse('col4')">
                <h5 class="card-title font-weight-semibold">Planning</h5>
                </div>
                <div>
                <div class="card-body" v-show="collapseOpen.col4">
                        
                    <div class="card" v-if="row.aupdl_is_lab == 'Y'">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Laboratorium</h6>
                        </div>
                        <div class="card-body p-3">
                        <table class="table table-bordered mb-3">
                            <thead>
                            <tr class="table-light">
                                <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Prioritas Pemeriksaan</h4>
                                    <p v-if="row.aupdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                    <p v-else>Non CITO</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Pasien Sedang Berpuasa?</h4>
                                    <p>{{row.aupdl_fasting == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="req-list-resume">
                            <div class="row g-3">
                            <template v-for="(v,k) in row.aupdl_data">
                                <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                <div class="card shadow-0 border">
                                    <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                                    </div>
                                    <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.dubData||[])" >
                                        <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)" :key="k1+'labdatas'">
                                        <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                        <template v-for="v2,k2 in (v1.data||[])">
                                            <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                            <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                            <span class="align-middle">{{v2.text||"-"}}</span>
                                            <span v-if="v2.notes">, {{v2.notes}}</span>
                                            </div>
                                        </template>
                                        </div>
                                    </template>
                                    </div>
                                </div>
                                </div>
                            </template>
                            <div class="col-md-4 col-lg-3" v-if="row.aupdl_hasil_lainnya">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                        <div>
                                            <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                            <span class="align-middle">{{row.aupdl_hasil_lainnya||"-"}}</span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <span v-if="!selectedLabInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                            </div>
                        </div>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (row.aupdl_hasil||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(row.aupdl_hasil||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="card" v-if="row.aupdr_is_radio == 'Y'">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Radiologi</h6>
                        </div>
                        <div class="card-body p-3">
                        <table class="table table-bordered mb-3">
                            <thead>
                            <tr class="table-light">
                                <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Prioritas Pemeriksaan</h4>
                                    <p v-if="row.aupdr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                    <p v-else>Non CITO</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                                    <p>{{row.aupdr_alergi == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="row g-3">
                            <template v-for="(v,k) in row.aupdr_data">
                            <div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">{{
                                    getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                                </div>
                                <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.data||[])">
                                    <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle">{{v1.text||"-"}}</span>
                                        <span v-if="v1.notes">, {{v1.notes}}</span>
                                        
                                        <a href="javascript:;" @click="openInformConsentDetailRadiologi(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                        <i class="icon-file-eye2"></i>
                                        </a>
                                    </div>
                                    </template>
                                </div>
                                </div>
                            </div>
                            </template>
                            <div class="col-md-4 col-lg-3" v-if="row.aupdr_hasil_lainnya">
                            <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                        <div>
                                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle">{{row.aupdr_hasil_lainnya||"-"}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <span v-if="!selectedRadioInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                        </div>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (row.aupdr_hasil||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(row.aupdr_hasil||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <table class="table table-bordered">
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Rencana Tindakan Pengobatan</h4>
                                <p>{{row.aupd_rencana_pengobatan||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </table>
                    </div>
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Resep</h6>
                        </div>
                        <div class="card-body p-3">
                        <table class="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <th>Jenis</th>
                                    <th>Nama</th>
                                    <th>Jumlah</th>
                                    <th>Dosis</th>
                                    <th>Keterangan</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(v,k) in (resepDokter||[])" :key="'resepDokter'+k">
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurd_jenis == 1 ? 'Obat' : v.aurd_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p v-if="v.aurd_item_id == 99999">
                                        {{v.aurd_item_lainnya||"-"}}
                                        </p>
                                        <p v-else>
                                        {{v.aurd_nama||"-"}}
                                        </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurd_jumlah||"-"}} {{v.aurd_satuan||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>
                                            <span v-if="v.aurd_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                            <span v-else>{{v.aurd_frekuensi_lainnya || "-"}}</span>
                                        </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurd_keterangan || "-"}}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(resepDokter||[]).length" class="table-info">
                                    <td class="text-center" colspan="99">Tidak Ada Data</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                        </div>
                        <div class="card-body p-3">
                        <table class="table table-bordered" v-if="(row.aupd_tindak_lanjut||[]).indexOf('RU') != -1">
                            <thead>
                            <tr class="table-secondary">
                                <th colspan="2">Tindak Lanjut</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colspan="2">
                                <div class="result_tab">
                                    <h4>Tindak Lanjut</h4>
                                    <p>Rujukan</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                <div class="result_tab">
                                    <h4>Alasan Merujuk</h4>
                                    <p>{{getConfigDynamic(Config.mr.alasanRujuk,row.aupd_alasan_rujuk)||"-"}} - {{row.aupd_alasan_rujuk_text||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Faskes Rujukan</h4>
                                    <p>{{getConfigDynamic(masterKajianDokter.mFaskes,row.aupd_faskes_rujuk)}}</p>
                                </div>
                                </td>
                                
                                <td>
                                <div class="result_tab">
                                    <h4>Peralatan Medis yang terpasang</h4>
                                    <p v-if="row.aupd_is_media == 'Y'">
                                      <ul>
                                        <li v-for="(vq,kq) in (row.aupd_media||[])" :key="kq">
                                            {{vq}}
                                        </li>
                                      </ul>
                                    </p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                <div class="result_tab">
                                    <h4>Perawatan yang pasien butuhkan</h4>
                                    <p>{{row.aupd_perawatan_pasien||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        
                        <table class="table table-bordered" v-else-if="(row.aupd_tindak_lanjut||[]).indexOf('RJ') != -1">
                            <thead>
                            <tr class="table-secondary">
                                <th colspan="2">Tindak Lanjut</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colspan="2">
                                <div class="result_tab">
                                    <h4>Tindak Lanjut Rawat Jalan</h4>
                                    <p>{{getConfigDynamic(Config.mr.tindakLanjutRawatJalan,row.aupd_rawat_jalan_tindakan)||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        
                        <table class="table table-bordered" v-else-if="(row.aupd_tindak_lanjut||[]).indexOf('RI') != -1">
                            <thead>
                            <tr class="table-secondary">
                                <th colspan="2">Tindak Lanjut</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colspan="2">
                                <div class="result_tab">
                                    <h4>Tindak Lanjut</h4>
                                    <p>Rawat Inap</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Ruangan</h4>
                                    <p>{{getConfigDynamic(masterKajianDokter.mRuangan,row.aupd_rawat_inap_ruangan)}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Nama DPJP</h4>
                                    <p>{{getConfigDynamic(masterKajianDokter.mDokterAll, row.aupd_dokter_ranap)}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="card">
            <div class="card-footer">
                <div class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
            </div>
            
            </b-form>
            </validation-observer>
        </div>

        
        <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
            <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
            <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
        </b-modal>
        
            
        <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
            <RiwayatRM v-bind="passToSubRM" />
        </b-modal>        
        
        <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
            <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
        </b-modal>
        
        <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
            <RiwayatRMUGD v-bind="passToSubRMUGD" />
        </b-modal>

        <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
            <table class="table table-bordered table-sm table-input">
            <thead>
                <tr>
                <td colspan="3">
                    <div class="result_tab">
                        <h4>Nama Tindakan / ICD-9-CM</h4>
                        <p>{{rowIC.mi9_name}} <span class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                    </div>
                </td>
                </tr>
                <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                <td>
                {{rowIC.auic_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td>
                {{rowIC.auic_dasar_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td>
                {{rowIC.auic_tindakan_dokter||"-"}}
                </td>
                </tr>
                <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td>
                {{rowIC.auic_indikasi_tindakan||"-"}}
                </td>
                </tr>
                <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td>
                {{rowIC.auic_tata_cara||"-"}}
                </td>
                </tr>
                <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td>
                {{rowIC.auic_tujuan||"-"}}
                </td>
                </tr>
                <tr>
                <td>7</td>
                <td>Risiko</td>
                <td>
                {{rowIC.auic_risiko||"-"}}
                </td>
                </tr>
                <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td>
                {{rowIC.auic_kompilasi||"-"}}
                </td>
                </tr>
                <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td>
                {{rowIC.auic_prognosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td>
                {{rowIC.auic_alternatif||"-"}}
                </td>
                </tr>
                <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td>
                {{rowIC.auic_lainnya||"-"}}
                </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div class="result_tab">
                    <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                    <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                    <p v-else>Tindakan ditolak</p>
                    </div>
                </td>
                <td width="50%">
                    <div class="result_tab">
                    <h4>Persetujuan tindakan diberikan kepada</h4>
                    <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                    <p v-else>Penanggung Jawab</p>
                    </div>
                </td>
                </tr>
            </tfoot>
            </table>
        </b-modal>
    </div>
</template>



<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  const moment = require('moment')
  import DetailKajianAwal from './DetailKajianAwal.vue'
  import DetailKajianLanjutan from './DetailKajianLanjutan.vue'
  import RiwayatRM from './RiwayatRM.vue'

  import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD.vue'
  import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD.vue'


  export default {
    extends: GlobalVue,
    data() {
      return {
        rowReg: {},
        mKesadaran: [],
        dataICD9: [],
        dataICD9Primer: null,
        dataICD10: [],
        dataICD10Primer: null,
        dataICD10Dok: [],
        dataICD9Dok: [],


        mICD9: [],
        mICD10: [],
        mRadiologi: [],
        mLab: [],
        riwayatObat: [],
        resepDokter: [],
        mObatPreferensi: [],
        mDosis: [],
        mAlatKesehatan: [],
        mRacikan: [],
        activeAnat: 0,


        // sini disini ya
        openDetailKajian: false,
        openTindakan: false,
        mPekerjaan: [],
        mAlatBantu: [],
        mSkrininGizi: [],
        mSkrininGiziV2: [],
        mSDKI: [],
        mSLKI: [],
        mSIKI: [],
        mPoli: [],
        mDokter: [],

        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwyatRMPerawatIS: '',
        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],

        dataRiwayatRMFollowUp: {},
        diagnosa: [],
        pemeriksaanFisik: false,

        inputICD9: null,
        inputICD10: null,

        inputICD9Name: null,
        inputICD10Name: null,

        searchICD10Name: null,
        searchICD9Name: null,

        nonFisioCollapse: {
          subjective: true,
          objective: false,
          assessment: false,
          planning: false,
        },

        
        openDetailKajianPerawatUGD: false,
        openRiwayatRMUGD: false,

        dataKajianPerawat: {},
        dataKajianDokter: {},

        historiKajianPerawat: [],
        historiKajianDokter: [],

        masterKajianPerawat: {},
        masterKajianDokter: {},

        masterTriase: {},
        dataKajianTriase: {},

        historiKajianRadiologi: [],
        historiKajianLab: [],
        historiKajianTriase: [],

        dataKajianRadiologi: {},
        dataKajianLab: {},

        dataKajianFarmasi: [],
        dataUGDReservasi: {},

        collapseOpen:{
            col1: false,
            col2: false,
            col3: false,
            col4: false,
        },

        keadaanUmum: false,

        openModalICDetail: false,
        rowIC: {}

      }
    },
    components: {
      DetailKajianAwal,
      DetailKajianLanjutan,
      RiwayatRM,
      KajianPerawatUGD,
      RiwayatRMUGD
    },
    computed: {
      passToSub() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          mPekerjaan: this.mPekerjaan,
          mAlatBantu: this.mAlatBantu,
          mSkrininGizi: this.mSkrininGizi,
          mSkrininGiziV2: this.mSkrininGiziV2,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSLKI: this.mSLKI,
          mSIKI: this.mSIKI,
          mPoli: this.mPoli,
          mDokter: this.mDokter,
          mKesadaran: this.mKesadaran,
          historyKajianPerawat: this.historyKajianPerawat
        })
      },
      passToSubLanjutan() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSIKI: this.mSIKI,
          mKesadaran: this.mKesadaran,
          historyKajianPerawat: this.historyKajianPerawat
        })
      },
      passToSubRM() {
        return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,

          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,

          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
        })
      },

      
        passToSubPerawatUGD(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                dataKajianPerawat: this.dataKajianPerawat,
                historiKajianPerawat: this.historiKajianPerawat,
                masterKajianPerawat: this.masterKajianPerawat,
            })
        },

        passToSubRMUGD(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                dataKajianDokter: this.dataKajianDokter,
                dataKajianTriase: this.dataKajianTriase,
                historiKajianDokter: this.historiKajianDokter,
                masterKajianDokter: this.masterKajianDokter,
                masterTriase: this.masterTriase,
                historiKajianRadiologi: this.historiKajianRadiologi,
                historiKajianLab: this.historiKajianLab,
                historiKajianTriase: this.historiKajianTriase,
                dataKajianRadiologi: this.dataKajianRadiologi,
                dataKajianLab: this.dataKajianLab,
                dataKajianFarmasi: this.dataKajianFarmasi,
                dataUGDReservasi: this.dataUGDReservasi
            })
        },

        airwaysVal(){
            let value = []
            if(this.row.auod_airways_pangkal_lidah == "Y"){
                value.push("Pangkal Lidah Jatuh")
            }
            if(this.row.auod_airways_sputum == "Y"){
                value.push("Sputum")
            }
            if(this.row.auod_airways_sputum == "Y"){
                value.push("Darah")
            }
            if(this.row.auod_airways_sputum == "Y"){
                value.push("Spasme")
            }
            if(this.row.auod_airways_sputum == "Y"){
                value.push("Benda Asing") 
            }
            if(!value.length){
                return "-"
            }else{
                return value.join(", ")
            }
        },
        circulationVal(){
            let value = []
            if(this.row.auod_circulation_akral_dingin == "Y"){
                value.push("Akral Dingin")
            }
            if(this.row.auod_circulation_kulit_kering == "Y"){
                value.push("Kulit Kering")
            }
            if(this.row.auod_circulation_pendarahan == "Y"){
                value.push("Pendarahan")
            }
            if(this.row.auod_circulation_pengisian_kapiler == "Y"){
                value.push("Pengisian Kapiler")
            }
            if(this.row.auod_circulation_nadi == "Y"){
                value.push("Nadi") 
            }
            if(this.row.auod_circulation_pucat == "Y"){
                value.push("Pucat") 
            }
            if(this.row.auod_circulation_sianosis == "Y"){
                value.push("Sianosis") 
            }
            if(this.row.auod_circulation_turgor_kulit_kurang == "Y"){
                value.push("Turgor Kulit Kurang") 
            }
            if(this.row.auod_circulation_lainnya == "Y"){
                value.push("Sirkulasi Lainnya") 
            }
            if(!value.length){
                return "-"
            }else{
                return value.join(", ")
            }
        },

    },
    mounted() {
      this.apiGet()
      this.apiGetMaster()
      setTimeout(() => {
        this.initSticky()
      }, 1000)
    },
    methods: {
        doOpenCollapse(col){
            this.collapseOpen[col] = !this.collapseOpen[col]
        },
        doOpenRiwayatRMUGD(v){
            this.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.openRiwayatRMUGD = true    
                this.dataKajianDokter = res.data
                this.loadingOverlay = false
            })

            Gen.apiRest(
                "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id+'&master=1', 
            ).then(res=>{
                this.masterKajianDokter = res.data
            })

            
            Gen.apiRest(
                "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.dataKajianTriase = res.data
            })
            
            Gen.apiRest(
                "/get/"+'UGDTriase'+'/'+'master', 
            ).then(res=>{
                this.masterTriase = res.data
            })

            
            Gen.apiRest(
                "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.dataKajianLab = res.data
            })
            
            Gen.apiRest(
                "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.dataKajianRadiologi = res.data
            })

            Gen.apiRest(
                "/do/"+'UGDAssesment',
                {data:{type:'get-riwayat-rekam-medis', aur_id: v.aurm_aur_id}}
            ).then(res=>{
                let resp = res.data
                this.historiKajianPerawat = resp.historyKajianPerawat
                this.historiKajianDokter = resp.historyKajianDokter
                this.historiKajianRadiologi = resp.historyKajianRadiologi
                this.historiKajianLab = resp.historyKajianLab
                this.historiKajianTriase = resp.historyKajianTriase
                this.loadingOverlay = false
            })

            Gen.apiRest(
                "/do/" + 'UGDAssesment', {
                    data: {
                    type: 'get-obat-farmasi',
                    regId: v.aurm_aur_id
                    }
                }
            ).then(res => {
                let resp = res.data
                this.dataKajianFarmasi = resp.data
                this.dataUGDReservasi = resp.dataUGDReservasi
                this.loadingOverlay = false
            })
            
            
            this.openRiwayatRMUGD = true
        },
    
        toDetailKajianPerawatUGD(){
            this.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aurm_aur_id, 
            ).then(res=>{
                this.openDetailKajianPerawatUGD = true    
                this.dataKajianPerawat = res.data
                this.loadingOverlay = false
            })
            Gen.apiRest(
                "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aurm_aur_id+'&master=1', 
            ).then(res=>{
                this.masterKajianPerawat = res.data
            })
        },

      toggleRmCollapse(e, collapseParamKey){
        e.stopPropagation()
        this.nonFisioCollapse[collapseParamKey] = !this.nonFisioCollapse[collapseParamKey]
        if(this.nonFisioCollapse[collapseParamKey]){
          const mainTarget = e.target.classList.contains('card-header') ? e.target : e.target.parentElement
          const pageHeaderOpen = document.querySelector('.page-header-sticky.page-header-sticky-open')
          const addCount = pageHeaderOpen ? pageHeaderOpen.offsetHeight : 0
          let count, addHeight = document.querySelector('.navbar').offsetHeight + addCount + 20
          setTimeout(() => {
            count = Math.round(mainTarget.parentElement.getBoundingClientRect().top + document.documentElement.scrollTop) - addHeight
            $("html, body").scrollTop(count)
          }, 50)
        }
      },
      apiGetMaster() {
        let slugs = (this.pageSlug ? "/" + this.pageSlug : "") + (this.pageId ? "/" + this.pageId : "")

        Gen.apiRest(
          "/get/" + this.modulePage +
          slugs + `?master=1&regId=${this.$route.query.regId}`, {}
        ).then(res => {
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
        })
      },
      // collapseShown(e){
      //   console.info("Hello!")
      // },
      ceilData(value) {
        return Math.ceil(value)
      },
      initSticky() {
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if (PageHeaderSticky) {
          const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
          if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
            PageHeaderToggle.addEventListener('click', function (e) {
              // e.preventDefault()
              PageHeaderSticky.classList.toggle('page-header-sticky-open')
              if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
              } else {
                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
              }
            })
          }
        }
      },

      filterICD10: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD10 || []).length; i++) {
          if (this.dataICD10[i]['auicd10r_icd_10']) {
            id.push(this.dataICD10[i]['auicd10r_icd_10'])
          }
        }

        this.searchICD10Name = e

        Gen.apiRest('/do/' + 'UGDRekamMedis', {
          data: {
            type: 'select-icd-10',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mICD10 = res.data.data
        })
      }, 10),


      changePageICD10(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }

        let data = {
          type: 'select-paging-icd-10',
          page: page,
          search:  this.searchICD10Name
        }

        Gen.apiRest(
          "/do/" + 'UGDRekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mICD10 = res.data.data
          this.getmICD10(this.dataICD10, k)
        })
      },

      filterICD9: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD9 || []).length; i++) {
          if (this.dataICD9[i]['auicd9r_icd_9']) {
            id.push(this.dataICD9[i]['auicd9r_icd_9'])
          }
        }
       
        this.searchICD9Name = e
       
        Gen.apiRest('/do/' + 'UGDRekamMedis', {
          data: {
            type: 'select-icd-9',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mICD9 = res.data.data
        })
      }, 10),

      changePageICD9(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }
        let data = {
          type: 'select-paging-icd-9',
          page: page,
          search:  this.searchICD9Name
        }
        Gen.apiRest(
          "/do/" + 'UGDRekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mICD9 = res.data.data
          this.getmICD9(this.dataICD9, k)
        })
      },


      getmICD9(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.auicd9r_icd_9
        })
        return _.filter(this.mICD9, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },

      getmICD10(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.auicd10r_icd_10
        })
        return _.filter(this.mICD10, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },
      changeTabAnat(k) {
        this.activeAnat = k
      },

      selectICD9(e) {
        let index = this.mICD9.findIndex(x => x.value == e)
        if (index !== -1) {
          this.inputICD9Name = this.mICD9[index]['text']
          this.searchICD9Name = null
        } else {
          this.inputICD9Name = null
        }
        this.addNewICD9()
      },
      selectICD10(e) {
        let index = this.mICD10.findIndex(x => x.value == e)
        if (index !== -1) {
          this.inputICD10Name = this.mICD10[index]['text']
          this.searchICD10Name = null        
        } else {
          this.inputICD10Name = null
        }
        this.addNewICD10()
      },

      doSubmit() {
        this.$refs['VFormSubmit'].validate().then(success => {
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }
                  if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }, 500)
              }
            })
          }
          
          if(!(this.dataICD10||[]).length){
            return  this.$swal({
              icon: 'warning',
              title: 'Tidak ada ada ICD 10',
            })
          }

          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.rowReg
                data.dataICD9 = this.dataICD9

                // if((data.dataICD9||[]).length){
                //     let indexPrimary = this.dataICD9.findIndex(x => x.auicd9r_id == this.dataICD9Primer)
                //     data.dataICD9[indexPrimary]['auicd9r_is_primary'] = 'Y'
                // }

                for (let i = 0; i < (this.dataICD10 || []).length; i++) {
                  this.dataICD10[i]['auicd10r_is_primary'] = 'N'
                }
                data.dataICD10 = this.dataICD10
                if ((data.dataICD10 || []).length) {
                  let indexPrimary2 = this.dataICD10.findIndex(x => x.auicd10r_id == this.dataICD10Primer)
                  data.dataICD10[indexPrimary2]['auicd10r_is_primary'] = 'Y'
                }

                data.type = 'submit-rm'
                Gen.apiRest(
                  "/do/" + 'UGDRekamMedis', {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok'
                  }).then(result => {
                    if (result.value) {
                      this.$router.push({
                        name: 'UGDRekamMedis'
                      }).catch(() => {})
                    }
                  })
                }).catch(err => {
                  this.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })
              }
            })
          }
        })
      },
      getConfigDynamic(master, value) {
        let text = ''
        if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index]['text']
          }
        }
        return text
      },

      isShowHeadLab(i) {
        let isData = 0
        for (let j = 0; j < (this.row.aupdl_data[i]['dubData'] || []).length; j++) {
          for (let k = 0; k < (this.row.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
            if (this.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
              isData += 1
            }
          }
        }
        return isData
      },
      isShowHeadSubHeadLab(i, j) {
        let isData = 0
        for (let k = 0; k < (this.row.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
          if (this.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
            isData += 1
          }
        }
        return isData
      },
      isShowLab(i, j, k) {
        let isData = 0
        if (this.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
          isData += 1
        }
        return isData
      },

      isShowHeadRadiologi(j) {
        let isData = 0
        for (let k = 0; k < (this.row.aupdr_data[j]['data'] || []).length; k++) {
          if (this.row.aupdr_data[j]['data'][k]['selected']) {
            isData += 1
          }
        }
        return isData
      },
      isShowRadiologi(j, k) {
        let isData = 0
        if (this.row.aupdr_data[j]['data'][k]['selected']) {
          isData += 1
        }
        return isData
      },

      selectedLabInput() {
        let data = []
        for (let ik = 0; ik < (this.row.aupdl_data || []).length; ik++) {
          for (let jk = 0; jk < (this.row.aupdl_data[ik]['dubData'] || []).length; jk++) {
            for (let kk = 0; kk < (this.row.aupdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
              if (this.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                data.push(this.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
              }
            }
          }
        }
        if (this.row.appdl_hasil_lainnya) {
          data.push(this.row.appdl_hasil_lainnya)
        }
        return data.join(", ")
      },

      selectedRadioInput() {
        let data = []
        for (let i = 0; i < (this.row.aupdr_data || []).length; i++) {
          for (let j = 0; j < (this.row.aupdr_data[i]['data'] || []).length; j++) {
            if (this.row.aupdr_data[i]['data'][j]['selected']) {
              data.push(this.row.aupdr_data[i]['data'][j]['text'])
            }
          }
        }
        if (this.row.aupdr_hasil_lainnya) {
          data.push(this.row.aupdr_hasil_lainnya)
        }
        return data.join(", ")
      },

      toDetail(v) {
        this.loadingOverlay = true
        Gen.apiRest(
          "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianPerawat + '?regId=' + v.arm_ar_id,
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.openDetailKajian = true
        })
      },
      doOpenRiwayatRM(v) {
        console.log(v)
        this.loadingOverlay = true
        if (v.kajianDokter) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokter = res.data
            this.dataRiwayatRMDokter.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokter = {}
        }

        if (v.kajianDokterTerapi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterTerapi = res.data
            this.dataRiwayatRMDokterTerapi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterTerapi = {}
        }

        if (v.kajianGizi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterGizi = res.data
            this.dataRiwayatRMDokterGizi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterGizi = {}
        }

        if (v.kajianFisio) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMFisio = res.data
            this.dataRiwayatRMFisio.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMFisio = {}
        }

        if (v.kajianRadiologi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMRadiologi = res.data
            this.dataRiwayatRMRadiologi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMRadiologi = {}
        }

        if (v.kajianLab) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMLab = res.data
            this.dataRiwayatRMLab.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMLab = {}
        }

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-obat-farmasi',
              regId: v.arm_ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          this.dataRiwayatRMFarmasi = resp.data
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.arm_ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          // non ppa
          this.historyKajianPerawat = resp.historyKajianPerawat
          this.historyKajianDokter = resp.historyKajianDokter
          this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
          
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: this.$route.query.regId
            }
          }
        ).then(res => {
          let resp = res.data
          this.loadingOverlay = false
        
          this.historyKajianDokterGizi = resp.historyKajianDokterGizi
          this.historyKajianFisio = resp.historyKajianFisio
          this.historyKajianRadiologi = resp.historyKajianRadiologi
          this.historyKajianLab = resp.historyKajianLab
        })


        Gen.apiRest(
          "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.arm_ar_id,
        ).then(res => {
          this.loadingOverlay = false
          this.dataRiwayatRMFollowUp = res.data
          this.dataRiwayatRMFollowUp.Config = this.Config
          this.openRwiayatRM = true
        })


        setTimeout(() => {
          this.loadingOverlay = false
          this.openRwiayatRM = true
        }, 2000)
      },


      doOpenRiwayatRMV2(v) {
        console.log(v)
        this.loadingOverlay = true
        if (v.kajianDokter) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokter = res.data
            this.dataRiwayatRMDokter.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokter = {}
        }

        if (v.kajianDokterTerapi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterTerapi = res.data
            this.dataRiwayatRMDokterTerapi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterTerapi = {}
        }

        if (v.kajianGizi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + v.ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterGizi = res.data
            this.dataRiwayatRMDokterGizi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterGizi = {}
        }

        if (v.kajianFisio) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + v.ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMFisio = res.data
            this.dataRiwayatRMFisio.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMFisio = {}
        }

        if (v.kajianRadiologi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + v.ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMRadiologi = res.data
            this.dataRiwayatRMRadiologi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMRadiologi = {}
        }

        if (v.kajianLab) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + v.ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMLab = res.data
            this.dataRiwayatRMLab.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMLab = {}
        }

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-obat-farmasi',
              regId: v.ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          this.dataRiwayatRMFarmasi = resp.data
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          // non ppa
          this.historyKajianPerawat = resp.historyKajianPerawat
          this.historyKajianDokter = resp.historyKajianDokter
          this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
          
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          this.loadingOverlay = false
        
          this.historyKajianDokterGizi = resp.historyKajianDokterGizi
          this.historyKajianFisio = resp.historyKajianFisio
          this.historyKajianRadiologi = resp.historyKajianRadiologi
          this.historyKajianLab = resp.historyKajianLab
        })


        Gen.apiRest(
          "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.ar_id,
        ).then(res => {
          this.loadingOverlay = false
          this.dataRiwayatRMFollowUp = res.data
          this.dataRiwayatRMFollowUp.Config = this.Config
          this.openRwiayatRM = true
        })


        setTimeout(() => {
          this.loadingOverlay = false
          this.openRwiayatRM = true
        }, 2000)
      },









    // INI REKAM MEDIS

      addNewICD9() {
        if (this.inputICD9) {
          // if (!this.row.isEdit) {
            let data = {
              auicd9r_aurm_id: this.row.aurm_id,
              auicd9r_aur_id: this.rowReg.aur_id,
              auicd9r_icd_9: this.inputICD9,
              type: 'add-icd-9-rm'
            }
            Gen.apiRest(
              "/do/" + 'UGDRekamMedis', {
                data: data
              },
              "POST"
            ).then(res => {
              let resp = res.data
              this.dataICD9.push(resp.row)
              this.inputICD9 = null
            })
          // } else {
          //   let data = {
          //     auicd9r_aurm_id: this.row.aurm_id,
          //     auicd9r_id: null
          //   }
          //   this.dataICD9.push(data)
          // }
        }
      },

      removedataICD9(id, k) {
        // if (!this.row.isEdit) {
          let data = {
            id: id,
            type: 'remove-icd-9-rm'
          }
          Gen.apiRest(
            "/do/" + 'UGDRekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.dataICD9.splice(k, 1)
          })
        // } else {
        //   this.dataICD9.splice(k, 1)
        // }
      },

      addNewICD10() {
        if (this.inputICD10) {
          // if (!this.row.isEdit) {
            let data = {
              auicd10r_aurm_id: this.row.aurm_id,
              auicd10r_aur_id: this.rowReg.aur_id,
              auicd10r_icd_10: this.inputICD10,
              type: 'add-icd-10-rm'
            }
            Gen.apiRest(
              "/do/" + 'UGDRekamMedis', {
                data: data
              },
              "POST"
            ).then(res => {
              let resp = res.data
              this.dataICD10.push(resp.row)
              this.inputICD10 = null
            })
          // } else {
          //   let data = {
          //     auicd10r_aurm_id: this.row.aurm_id,
          //     auicd10r_id: null
          //   }
          //   this.dataICD10.push(data)
          // }
        }
      },

      removedataICD10(id, k) {
        // if (!this.row.isEdit) {
          let data = {
            id: id,
            type: 'remove-icd-10-rm'
          }
          Gen.apiRest(
            "/do/" + 'UGDRekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.dataICD10.splice(k, 1)
          })
        // } else {
        //   this.dataICD10.splice(k, 1)
        // }
      },

      autoSaveICD9: _.debounce(function (data) {
        let dataPost = {
          type: 'auto-save-icd-9-rm',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest(
            "/do/" + 'UGDRekamMedis', {
              data: dataPost
            },
            "POST"
          )
        }
      }, 1000),

      autoSaveICD10: _.debounce(function (data) {
        let dataPost = {
          type: 'auto-save-icd-10-rm',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest(
            "/do/" + 'UGDRekamMedis', {
              data: dataPost
            },
            "POST"
          )
        }
      }, 1000),

      
      openInformConsentDetailRadiologi(v,k1,k,kat){
          Gen.apiRest('/do/' + 'UGDFormDokter', {
              data: {
                  type: 'get-data-ic',
                  id: v.inform_consent,
              }
          }, 'POST').then(res => {
              this.rowIC = res.data.row 
              this.rowIC.tindakan = v.text  + "("+kat+")"
              this.rowIC.id_tindakan = v.inform_consent
                
              this.rowIC.k1 = k1
              this.rowIC.k = k

              this.openModalICDetail = true                   
          })
      }, 
      openInformConsentDetail(v){
          console.log(v)
          Gen.apiRest('/do/' + 'UGDFormDokter', {
              data: {
                  type: 'get-data-ic',
                  id: v.auicd9d_informed_consent,
              }
          }, 'POST').then(res => {
              this.rowIC = res.data.row 
              this.rowIC.mi9_name = v.mi9_name
              this.rowIC.mi9_code = v.mi9_code
              this.rowIC.id_icd = v.auicd9d_id
              this.openModalICDetail = true                   
          })
      },
    },
    watch: {
      $route() {
        this.apiGet()
        this.apiGetMaster()
      },
      'openDetailKajian'(v) {
        if (!v) {
          this.apiGet()
        }
      },
      'openRwiayatRM'(v) {
        if (!v) {
          this.apiGet()
        }
      },
      dataICD9: {
        handler(v) {
          this.autoSaveICD9(v)
        },
        deep: true
      },
      dataICD10: {
        handler(v) {
          this.autoSaveICD10(v)
        },
        deep: true
      },
    }
  }
</script>